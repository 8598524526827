import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { ActionsContainer, StyledOption } from "../styled";

export const AmendmentActions = ({ onPdfOpen }: { onPdfOpen: () => void }) => {
  return (
    <Menu
      type={"select"}
      options={[
        {
          label: "delete",
          children: (
            <StyledOption onClick={onPdfOpen}>
              <Icons name="IconEye" fill="transparent" />
              Visualizar aditivo
            </StyledOption>
          ),
        },
      ]}
      disableAutoFocusItem={true}
      anchorOrigin={{ vertical: 0, horizontal: -210 }}
    >
      <ActionsContainer>
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </ActionsContainer>
    </Menu>
  );
};
