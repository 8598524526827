import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

// TODO: style fixes - remove after review DataGrid on DS
export const DataGridStylesContainer = styled.div`
  & .tab-content {
    padding: 0;
    display: none;
  }

  & .tab-content,
  & .tab-content-active {
    transition: none;
  }
`;

// TODO: style fixes - remove after review DataGrid on DS
export const SubDataGridStylesContainer = styled.div`
  & .data-grid-table-border {
    border-radius: 0;
  }

  &
    .data-grid-table-container
    .data-grid-table-body-container
    .data-grid-table-body-subcontainer
    .data-grid-table-body-td {
    background: ${({ theme }) => theme.colors.neutral[95]};
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  }
`;

export const StyledOption = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const DataGridHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const TotalAmendmentsText = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;
