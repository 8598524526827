import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;
const Styledtext = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledTableText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-direction: "row";
`;

const FirstColumnCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const FirstColumnCellData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
`;

const EmailIcon = styled(Icons)`
  color: var(--color-neutral-70);
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
const EmailText = styled(Typography)`
  color: var(--color-neutral-60);
`;

const PdfViewerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  flex-direction: column;
`;
export {
  StyledSecondary,
  StyledTitle,
  Styledtext,
  StyledTableText,
  StyledFlexRow,
  FirstColumnCell,
  FirstColumnCellData,
  EmailIcon,
  EmailWrapper,
  EmailText,
  PdfViewerContainer,
};
