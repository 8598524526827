import {
  StyledBox,
  StyledGapHorizontalContainer,
  StyledHorizontalContainer,
  StyledTag,
  StyledText,
  StyledTitle,
} from "./styled";

const ProductBox = ({ signatory, active = true }) => {
  const signupDate = signatory?.signedAt
    ? new Date(signatory.signedAt).toLocaleDateString("pt-BR")
    : "-";

  return (
    <StyledBox>
      <StyledHorizontalContainer>
        <StyledTitle variant="body3">Seu produto</StyledTitle>
        {active ? (
          <StyledTag variant="primary">Ativo</StyledTag>
        ) : (
          <StyledTag variant="disabled">Inativo</StyledTag>
        )}
      </StyledHorizontalContainer>
      <StyledGapHorizontalContainer>
        <div>
          <StyledTitle variant="body3">{signupDate}</StyledTitle>
          <StyledText variant="body4">Contratação</StyledText>
        </div>
        <div>
          <StyledTitle variant="body3">{signupDate}</StyledTitle>
          <StyledText variant="body4">Última alteração</StyledText>
        </div>
      </StyledGapHorizontalContainer>
    </StyledBox>
  );
};

export { ProductBox };
