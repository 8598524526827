import { Icons, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DataGridHeaderContainer, TotalAmendmentsText } from "../styled";

interface ContractHeaderProps {
  name: string;
  amendmentsQuantity: number;
}

export const ContractHeader = ({
  name,
  amendmentsQuantity,
}: ContractHeaderProps) => {
  const showAmendments = amendmentsQuantity > 0;
  const amendmentsText = amendmentsQuantity > 1 ? "aditivos" : "aditivo";

  return (
    <DataGridHeaderContainer>
      <ShapeIcon
        name="IconLicense"
        variant="default"
        color="var(--color-secondary-50)"
        size={40}
      />
      <div>
        <Typography variant="body3" style={{ fontWeight: 600 }}>
          {name}
        </Typography>
        {showAmendments && (
          <TotalAmendmentsText variant="body4" color="var(--color-neutral-60)">
            <Icons
              name="IconFilePlus"
              color="var(--color-neutral-60)"
              size={16}
            />
            {amendmentsQuantity} {amendmentsText}
          </TotalAmendmentsText>
        )}
      </div>
    </DataGridHeaderContainer>
  );
};
