import { DataGrid } from "@flash-tecnologia/hros-web-ui-v2";
import { SubDataGridStylesContainer } from "../styled";
import { AmendmentHeader } from "./amendment-header";
import { StyledActiveTag } from "@components/ContractStatusTag/styled";
import { AmendmentActions } from "./amendment-actions";

interface Amendment {
  status: string;
  id: string;
  displayName: string;
}

interface AmendmentSubgridProps {
  contractId: string;
  amendments: Amendment[];
  onPdfOpen: (contractId: string, amendmentId: string) => void;
}

export const AmendmentSubgrid = ({
  contractId,
  amendments,
  onPdfOpen,
}: AmendmentSubgridProps) => {
  return (
    <SubDataGridStylesContainer>
      <DataGrid
        columns={[
          {
            Header: "Aditivo",
            accessor: "displayName",
            disableSortBy: true,
            Cell: ({ value }) => <AmendmentHeader name={value} />,
          },
          {
            Header: "Início do contrato",
            accessor: "signDate",
            disableSortBy: true,
          },
          {
            Header: "Fim do contrato",
            accessor: "expirationDate",
            disableSortBy: true,
          },
          {
            Header: "Status",
            accessor: "status",
            disableSortBy: true,
            Cell: ({ value }) => (
              <StyledActiveTag
                variant={value === "Vigente" ? "primary" : "disabled"}
              >
                {value}
              </StyledActiveTag>
            ),
          },
          {
            Header: "Ações",
            accessor: "actions",
            sticky: "right",
            disableSortBy: true,
            Cell: (rowData) => {
              const amendmentId = rowData.row.original.id;
              return (
                <AmendmentActions
                  onPdfOpen={() => onPdfOpen(contractId, amendmentId)}
                />
              );
            },
          },
        ]}
        data={amendments.map((amendment) => ({
          ...amendment,
          signDate: "-",
          expirationDate: "-",
        }))}
      />
    </SubDataGridStylesContainer>
  );
};
