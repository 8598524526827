import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid #ebe6e9;
    border-radius: 8px;
    box-shadow: none;

    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 24px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)``;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 40px;
`;

const StyledDiv = styled.div`
  padding: 24px;
`;

export {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledTitle,
  StyledText,
  StyledContainerIcon,
  StyledDiv,
};
