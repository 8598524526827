import {
  IconButton,
  Icons,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledModal = styled(Modal.Root)`
  & {
    .modal-content-area {
      padding: 0px !important;
    }
    .modal-container {
      border-radius: 16px !important;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;
const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;
const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;
const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export {
  StyledModal,
  StyledIconButton,
  StyledIcon,
  StyledTitle,
  StyledText,
  StyledValue,
};
