import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledActiveTag = styled(Tag)`
  cursor: auto !important;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.feedback.success[90]};
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

const StyledInactiveTag = styled(Tag)`
  cursor: auto !important;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export { StyledActiveTag, StyledInactiveTag };
