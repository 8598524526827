import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { ActionsContainer, StyledOption } from "../styled";
import { useNavigate } from "react-router-dom";

type ContractActionsProps = {
  contractId: string;
  onPdfOpen: () => void;
};

export const ContractActions = ({
  contractId,
  onPdfOpen,
}: ContractActionsProps) => {
  const navigate = useNavigate();

  const navigateToSummary = () => {
    navigate(`/contract-manager/contracts-and-amendments/${contractId}`);
  };

  return (
    <Menu
      type={"select"}
      options={[
        {
          label: "delete",
          children: (
            <StyledOption onClick={onPdfOpen}>
              <Icons name="IconEye" fill="transparent" />
              Visualizar contrato completo
            </StyledOption>
          ),
        },
        {
          label: "delete",
          children: (
            <StyledOption onClick={navigateToSummary}>
              <Icons name="IconLicense" fill="transparent" />
              Resumo do contrato
            </StyledOption>
          ),
        },
      ]}
      disableAutoFocusItem={true}
      anchorOrigin={{ vertical: 0, horizontal: -210 }}
    >
      <ActionsContainer>
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </ActionsContainer>
    </Menu>
  );
};
