import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PageHeader, PDFViewer } from "@flash-tecnologia/hros-web-ui-v2";

import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { Grid } from "@mui/material";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { ConsultantContactBox } from "../../components/ConsultantContactBox";
import { GenericProfilePicture } from "../../components/GenericProfilePicture";
import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  StyledDataGrid,
  StyledFlexRow,
  StyledTableText,
  Styledtext,
  StyledTitle,
} from "./styled";

import { trpc } from "../../api/client";
import { useGetContractSignedUrl } from "../ContractsAndAmendmentsPage/hooks";

const ContractPage = () => {
  const { selectedCompany } = useSelectedCompany();
  const { getContractSignedUrl } = useGetContractSignedUrl();
  const [contracts, SetContracts] = useState<any>([]);
  const [templatePdf, setTemplatePdf] = useState<string>(
    "https://drive.google.com/file/d/10-PvCh4uy6eMGN9a2eUdDUalwaQF3ESz/preview"
  );

  const { data: dataV2, isLoading: isLoadingV2 } =
    trpc.getContractsByCompany.useQuery({ companyId: selectedCompany?.id });

  const navigate = useNavigate();

  const columns = [
    // {
    //   Header: "Modificação feita por",
    //   accessor: "employee",
    //   Cell: ({
    //     row: {
    //       original: { employee },
    //     },
    //   }) => {
    //     if (employee) {
    //       return (
    //         <FirstColumnCell>
    //           <GenericProfilePicture
    //             size={40}
    //             name={employee?.name}
    //             source={null}
    //           />
    //           <FirstColumnCellData>
    //             <StyledTableText variant="body4">
    //               {employee?.name}
    //             </StyledTableText>
    //             {!!employee?.email && (
    //               <EmailWrapper>
    //                 <EmailIcon name="IconMail" size={16} fill="transparent" />
    //                 <EmailText variant="caption">{employee?.email}</EmailText>
    //               </EmailWrapper>
    //             )}
    //           </FirstColumnCellData>
    //         </FirstColumnCell>
    //       );
    //     } else {
    //       return (
    //         <FirstColumnCell>
    //           <GenericProfilePicture size={40} name={""} source={null} />
    //           <FirstColumnCellData>
    //             <StyledTableText variant="body4">{""}</StyledTableText>
    //           </FirstColumnCellData>
    //         </FirstColumnCell>
    //       );
    //     }
    //   },
    // },

    {
      Header: "Data da modificação",
      accessor: "date",
      Cell: ({
        row: {
          original: { date = null },
        },
      }) => {
        return (
          <StyledFlexRow>
            <StyledTableText variant="body3">{date}</StyledTableText>
          </StyledFlexRow>
        );
      },
    },
    {
      Header: "Tipo de modificação",
      accessor: "type",
      Cell: ({
        row: {
          original: { type },
        },
      }) => {
        let status = "";
        switch (type) {
          case "ACTIVE":
            status = "Assinatura";
            break;

          case "CANCELLED":
            status = "Cancelamento";
            break;

          case "WAITING_SIGNATURE":
            status = "Aguardando meio de pagamento";
            break;

          default:
            status = "";
            break;
        }
        return (
          <StyledFlexRow>
            <StyledTableText variant="body3">{status}</StyledTableText>
          </StyledFlexRow>
        );
      },
    },

    // {
    //   Header: "Ações",
    //   accessor: "actions",
    //   sticky: "right",
    //   disableSortBy: true,
    //   Cell: ({
    //     row: {
    //       original: { card = null },
    //     },
    //   }) => {
    //     return (
    //       <Menu
    //         transformOrigin={{ horizontal: "right", vertical: "top" }}
    //         anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    //         type="select"
    //         options={[
    //           {
    //             onClick: () => {},
    //             children: (
    //               <StyledFlex>
    //                 <StyledTableText variant="body3">
    //                   Ver detalhes
    //                 </StyledTableText>
    //               </StyledFlex>
    //             ),
    //           },
    //         ]}
    //       >
    //         <div
    //           style={{
    //             width: "100%",
    //             alignItems: "center",
    //             display: "flex",
    //             justifyContent: "flex-end",
    //             paddingRight: "12px",
    //           }}
    //         >
    //           <SubImageIcon
    //             hasNoImage={true}
    //             size="small"
    //             variant="filled"
    //             icon="IconDotsVertical"
    //           />
    //         </div>
    //       </Menu>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    (async () => {
      if (dataV2) {
        const peopleContract = dataV2?.filter((contract) =>
          contract?.planIds?.some((plan) => plan === "people")
        );

        const mostRecentContract = peopleContract?.reduce((a, b) => {
          if (a?.signDate && b?.signDate) {
            return new Date(a?.signDate) > new Date(b?.signDate) ? a : b;
          }
          return a;
        });
        const arr = peopleContract?.map((c) => {
          // const employee = c?.employee?.find((emp) => {
          //   return emp;
          // });
          return {
            type: c?.status,
            // employee: employee,
            date: c?.signDate,
          };
        });
        SetContracts(arr);

        const pdfSignedUrl = await getContractSignedUrl({
          contractId: mostRecentContract?.id,
        });
        setTemplatePdf(pdfSignedUrl?.url);
      }
    })();
  }, [dataV2]);

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => {
                  navigate(`/contract-manager/plan`);
                },
                label: "Planos e Contratos",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Seu contrato",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3} style={{ padding: "0px 32px 32px 32px" }}>
        <Grid item md={6} xs={12}>
          <StyledTitle variant="headline6">Seu contrato</StyledTitle>
          <Styledtext style={{ marginTop: "8px" }} variant="body3">
            Você pode visualizar todas as versões do seu contrato e cancelar sua
            assinatura.
          </Styledtext>
        </Grid>
        <Grid item md={10} xs={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginBottom: "20px",
              flexDirection: "column",
            }}
          >
            <PDFViewer
              style={{ borderRadius: 8.5 }}
              width="100%"
              height="470px"
              src={templatePdf}
              options={{
                showToolbar: true,
                fit: "height",
                page: 1,
              }}
            />
            <div>
              {/* <StyledLinkButton
                      style={{ marginTop: "9px" }}
                      onClick={() => navigate(`/contract-manager/cancel-plan`)}
                      variant="primary"
                    >
                      Cancelar assinatura
                    </StyledLinkButton> */}
            </div>
          </div>
        </Grid>
        <Grid item md={10} xs={12}>
          <StyledDataGrid
            columns={columns}
            data={(contracts as []) || []}
            loading={isLoadingV2}
            hasPagination={true}
            pageSizeOptions={[
              { label: "5 itens", value: 5 },
              { label: "10 itens", value: 10 },
              { label: "20 itens", value: 20 },
            ]}
          />
        </Grid>
        <Grid item md={10} xs={12}>
          <ConsultantContactBox />
        </Grid>
      </Grid>
    </>
  );
};

export { ContractPage };
