import styled from "styled-components";
import { Grid } from "@mui/material";

import {
  Typography,
  TextField,
  PageContainer,
  DatePicker,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledTypography = styled(Typography)``;

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledGrid = styled(Grid)``;

const StyledDatePicker = styled(DatePicker)``;

const StyledBreadcrumbItem = styled.div`
  position: relative;
  background: transparent;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-right: 2px;
  padding-bottom: 4px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral.dark.dark5};
    transition: all 0.2s ease-in;
  }

  &:hover {
    &:before {
      width: 65%;
    }
  }
`;

const StyledBreadcrumbText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  border-radius: 2px;
  font-weight: 700;
`;

const StyledBreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  fill: transparent;
`;

const StyledPageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  StyledContainer,
  StyledTextField,
  StyledTypography,
  StyledSectionContainer,
  StyledSubtitleContainer,
  StyledTitle,
  StyledFlex,
  StyledGrid,
  StyledPageContainer,
  StyledDatePicker,
  StyledBreadcrumbItem,
  StyledBreadcrumbText,
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
};
