import { StyledLinkButton } from "../FooterBar/styled";
import { StyledBox, StyledIcon, StyledTag, StyledTitle } from "./styled";

const ModulesBox = ({
  icon,
  title,
  module,
  isUpgrade = false,
  setOpenModulesModal,
  setModalType,
  segment,
}) => {
  return (
    <StyledBox>
      <StyledIcon name={icon} fill="transparent" size={24} />
      {isUpgrade ? (
        <StyledTag
          style={{ backgroundColor: "#EBE6E9", color: "#1D161B" }}
          variant="primary"
        >
          Adicional
        </StyledTag>
      ) : (
        <></>
      )}

      <div>
        <StyledTitle variant="body3" style={{ fontWeight: 600 }}>
          {title}
        </StyledTitle>
        {/* {isUpgrade && <StyledText variant="body4">+ R$ 2,00</StyledText>} */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <StyledLinkButton
          variant="secondary"
          onClick={() => {
            segment();
            setOpenModulesModal(true);
            setModalType(module);
          }}
        >
          Saiba mais
        </StyledLinkButton>
        {/* {isUpgrade && (
          <StyledIconButton
            size="small"
            variant="line"
            icon={included ? "IconPencil" : "IconPlus"}
            onClick={() => {
              navigate(`/contract-manager/edit-plan`);
            }}
          />
        )} */}
      </div>
    </StyledBox>
  );
};

export { ModulesBox };
