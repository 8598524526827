import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;
const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledButtonText = styled(Typography)<{ checked: boolean }>`
  color: ${(props) => (props.checked ? "#F20D7A" : "#6B5B66")};
`;

const StyledSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const AlertContainer = styled.div<{ borderColor: string }>`
  margin-bottom: 40px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 8px;
  padding: 24px;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

const StyledAlertText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.info[50]};
  padding: 12px;
  background: ${({ theme }) => theme.colors.feedback.info[90]};
  border-radius: 48px;
  height: 48px;
  width: 48px;
`;

const StyledTextArea = styled.textarea`
  border: 1px solid #b9acb4;
  border-radius: 12px;
  padding: 16px;
  width: 100%;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.secondary.dark.dark5};
    outline: none;
  }
`;

const StyledButton = styled(Button)<{ checked: boolean }>`
  &.MuiPaper-root.MuiButtonBase-root.size-small {
    background: ${(props) => (props.checked ? "#FFF5FA" : "#f8f6f8")};
    border-color: ${(props) => (props.checked ? "#FE2B8F" : "")};
    &:hover {
      background: ${(props) => (props.checked ? "#FFCCE4" : "#EBE6E9")};
    }
  }
`;

export {
  StyledTitle,
  StyledText,
  StyledButtonText,
  StyledSecondary,
  AlertContainer,
  StyledAlertText,
  StyledIcon,
  StyledTextArea,
  StyledButton,
};
