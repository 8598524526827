import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledContainerIcon,
  StyledDiv,
  StyledText,
  StyledTitle,
} from "./styled";

const AccordionInfo = ({ title, text, info, segment }) => {
  return (
    <StyledAccordion>
      <StyledAccordionSummary
        onClick={() => {
          segment();
        }}
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <StyledTitle variant="headline8">{title}</StyledTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Divider />
        <StyledDiv>
          <StyledText variant="body3">{text}</StyledText>
        </StyledDiv>
        <div>{info}</div>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export { AccordionInfo };
