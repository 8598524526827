import { segmentTracking } from "@flash-tecnologia/hros-web-utility";

type ObjectLiteral = {
  [key: string]: string;
};

const segment = ({
  track,
  params,
  identify,
}: {
  track: string;
  params?: ObjectLiteral;
  identify?: {
    id: string;
    params?: ObjectLiteral;
  };
}) => {
  segmentTracking({
    track,
    module: "contract-manager",
    businessUnit: "people",
    params,
    identify,
  });
};

export { segment };
