import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CancelPlanPage } from "../page/CancelPlanPage";
import { ContractPage } from "../page/ContractPage";

import { PlansAndContractsPage } from "../page/PlansAndContractsPage";
import { RoutesGuard } from "./routesGuard";
import { BenefitsContractPage } from "src/page/BenefitsContractPage";
import { ContractsAndAmendmentsPage } from "src/page/ContractsAndAmendmentsPage";
import { ContractSummaryPage } from "src/page/ContractSummaryPage";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RoutesGuard />}>
          <Route
            path="/contract-manager/plan"
            element={<PlansAndContractsPage />}
          />
          <Route
            path="/contract-manager/cancel-plan"
            element={<CancelPlanPage />}
          />
          <Route
            path="/contract-manager/benefits/contract"
            element={<BenefitsContractPage />}
          />
          <Route
            path="/contract-manager/contracts-and-amendments/:contractId"
            element={<ContractSummaryPage />}
          />
          <Route
            path="/contract-manager/contracts-and-amendments"
            element={<ContractsAndAmendmentsPage />}
          />
          <Route path="/contract-manager/contract" element={<ContractPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
