import { Grid, Skeleton } from "@mui/material";
import { ConsultantContactBox } from "../../ConsultantContactBox";
import { ContractBox } from "@components/ContractBox";
import { trpc } from "../../../api/client";
import { segment } from "../../../utils/segment";
import { ProductBox } from "@components/ProductBox";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

const BenefitsPlanPage = () => {
  const { selectedCompany } = useSelectedCompany();

  const { data: contract, isLoading } =
    trpc.getBenefitsContractByCompany.useQuery({
      companyId: selectedCompany.id,
    });

  if (isLoading)
    return (
      <>
        <Grid container spacing={3}>
          <Grid item md={9} xs={12}>
            <Skeleton variant="rectangular" width="100%" height="185px" />
          </Grid>
          <Grid item md={3} xs={12}>
            <Skeleton variant="rectangular" width="100%" height="185px" />
          </Grid>
          <Grid item md={12} xs={12}>
            <Skeleton variant="rectangular" width="100%" height="250px" />
          </Grid>
        </Grid>
      </>
    );

  const signatory =
    contract?.signatories?.reduce(function (prev, current) {
      return prev.signedAt > current.signedAt ? prev : current;
    }) || null;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={9} xs={12}>
          <ProductBox signatory={signatory} active={contract?.active} />
        </Grid>
        <Grid item md={3} xs={12}>
          {contract?.active && (
            <ContractBox
              contractId={contract?._id}
              url={"/contract-manager/benefits/contract"}
              segment={() =>
                segment({
                  track:
                    "company_managecompany_finance_plansandcontracts_checkcontract_clicked",
                })
              }
            />
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <ConsultantContactBox cancel={false} />
        </Grid>
      </Grid>
    </>
  );
};

export { BenefitsPlanPage };
