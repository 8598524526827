import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader, PDFViewer } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid, Skeleton } from "@mui/material";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { ConsultantContactBox } from "../../components/ConsultantContactBox";
import { PdfViewerContainer, Styledtext, StyledTitle } from "./styled";

import { trpc } from "../../api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

const BenefitsContractPage = () => {
  const [templatePdf, setTemplatePdf] = useState<string>("");
  const { selectedCompany } = useSelectedCompany();

  const { data, isLoading } = trpc.getBenefitsContractByCompany.useQuery({
    companyId: selectedCompany.id,
  });

  useEffect(() => {
    setTemplatePdf(data?.contractTemplate?.url?.path || "");
  }, [data]);

  const navigate = useNavigate();

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => {
                  navigate(`/contract-manager/plan`);
                },
                label: "Planos e Contratos",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Seu contrato",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3} style={{ padding: "0px 32px 32px 32px" }}>
        <Grid item md={6} xs={12}>
          <StyledTitle variant="headline6">Seu contrato</StyledTitle>
          <Styledtext style={{ marginTop: "8px" }} variant="body3">
            Você pode visualizar todas as versões do seu contrato.
          </Styledtext>
        </Grid>
        <Grid item md={10} xs={12}>
          <PdfViewerContainer>
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height="650px" />
            ) : (
              <PDFViewer
                style={{ borderRadius: 8.5 }}
                width="100%"
                height="650px"
                src={templatePdf}
                options={{
                  showToolbar: true,
                  fit: "height",
                  page: 1,
                }}
              />
            )}
          </PdfViewerContainer>
        </Grid>
        <Grid item md={10} xs={12}>
          <ConsultantContactBox />
        </Grid>
      </Grid>
    </>
  );
};

export { BenefitsContractPage };
