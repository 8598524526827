import { useNavigate } from "react-router-dom";
import {
  PageHeader,
  DataGrid,
  Typography,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { ContractPdfModal } from "@components/ContractPdfModal";
import { useState } from "react";
import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { ContractStatusTag } from "@components/ContractStatusTag";
import { useGetAmendmentSignedUrl, useGetContractSignedUrl } from "./hooks";
import {
  AmendmentSubgrid,
  ContractActions,
  ContractHeader,
} from "./components";
import { DataGridStylesContainer } from "./styled";

const ContractsAndAmendmentsPage = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const [modalOpen, setModalOpen] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { data, isLoading, refetch } = trpc.getContractsByCompany.useQuery({
    companyId: selectedCompany.id,
  });
  const { getAmendmentSignedUrl } = useGetAmendmentSignedUrl();
  const { getContractSignedUrl } = useGetContractSignedUrl();

  const openPdfModal = async (contractId: string, amendmentId?: string) => {
    setIsPdfLoading(true);
    setModalOpen(true);

    const pdfUrl = amendmentId
      ? await getAmendmentSignedUrl({ contractId, amendmentId })
      : await getContractSignedUrl({ contractId });

    setPdfUrl(pdfUrl.url);
    setIsPdfLoading(false);
  };

  const onCloseModal = () => {
    setModalOpen(false);
    setPdfUrl("");
  };

  if (!isLoading && !data) refetch();

  const tableData = data?.map(
    ({ amendments, commercialConditions, ...contract }) => ({
      ...contract,
      signDate: contract.signDate || "-",
      expirationDate: contract.expirationDate || "-",
      amendmentsQuantity: amendments.length,
      children: (
        <AmendmentSubgrid
          contractId={contract.id}
          amendments={amendments}
          onPdfOpen={openPdfModal}
        />
      ),
    })
  );

  const breadcrumbs = [
    {
      onClick: () => navigate(`/settings`, { state: { tab: 2 } }),
      label: "Gerenciar empresa",
      active: true,
    },
    {
      onClick: () => null,
      label: "Contratos e aditivos",
    },
  ];

  return (
    <PageContainer>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={breadcrumbs}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="headline6" color="var(--color-neutral-dark2)">
            Contratos e aditivos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGridStylesContainer>
            <DataGrid
              withAccordion
              loading={isLoading}
              columns={[
                {
                  Header: "Contrato",
                  accessor: "displayName",
                  disableSortBy: true,
                  Cell: ({ value, row }) => {
                    return (
                      <ContractHeader
                        name={value}
                        amendmentsQuantity={row.original.amendmentsQuantity}
                      />
                    );
                  },
                },
                {
                  Header: "Início do contrato",
                  accessor: "signDate",
                  disableSortBy: true,
                },
                {
                  Header: "Fim do contrato",
                  accessor: "expirationDate",
                  disableSortBy: true,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  disableSortBy: true,
                  Cell: ({ value }) => <ContractStatusTag status={value} />,
                },

                {
                  Header: "Ações",
                  accessor: "actions",
                  disableSortBy: true,
                  Cell: (rowData) => {
                    const contractId = rowData.row.original.id;
                    return (
                      <ContractActions
                        contractId={contractId}
                        onPdfOpen={() => openPdfModal(contractId)}
                      />
                    );
                  },
                },
                {
                  Header: "Children",
                  accessor: "children",
                },
              ]}
              data={tableData}
            />
          </DataGridStylesContainer>
        </Grid>
      </Grid>
      <ContractPdfModal
        isOpen={modalOpen}
        onClose={onCloseModal}
        isLoading={isPdfLoading}
        pdfUrl={pdfUrl}
      />
    </PageContainer>
  );
};

export { ContractsAndAmendmentsPage };
