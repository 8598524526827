import { Skeleton, PDFViewer } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledIconButton,
  StyledModal,
  StyledText,
  PdfViewerContainer,
  StyledVerticalSpacing,
} from "./styled";

type ContractPdfModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  pdfUrl: string;
};

const ContractPdfModal = ({
  isOpen,
  isLoading,
  onClose,
  pdfUrl,
}: ContractPdfModalProps) => {
  const modalHeader = (
    <StyledIconButton
      size="small"
      variant="line"
      icon="IconX"
      onClick={() => {
        onClose();
      }}
    />
  );

  return (
    <StyledModal
      header={modalHeader}
      open={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <StyledText variant="headline4" style={{ color: "black" }}>
          Visualizar contrato
        </StyledText>
        <StyledVerticalSpacing />
        <StyledVerticalSpacing />
        <PdfViewerContainer>
          {isLoading && (
            <Skeleton variant="rectangular" width="100%" height="650px" />
          )}
          {!isLoading && (
            <PDFViewer
              style={{ borderRadius: 8.5 }}
              width="100%"
              height="650px"
              src={pdfUrl}
              options={{
                showToolbar: true,
                fit: "height",
                page: 1,
              }}
            />
          )}
        </PdfViewerContainer>
      </>
    </StyledModal>
  );
};

export { ContractPdfModal };
