import { SecondaryTabCard } from "../SecondaryTabCard";
import { SecondaryTabsContainer } from "./styled";

export const SecondaryTabs = ({
  secondaryTabs,
  selectedSecondaryTab,
  setSelectedSecondaryTab,
}) => {
  return (
    <SecondaryTabsContainer>
      {secondaryTabs.map(({ key, title }) => {
        const isSelected = key === selectedSecondaryTab?.key;

        return (
          <SecondaryTabCard
            key={key}
            id={key}
            title={title}
            isSelected={isSelected}
            setSelectedSecondaryTab={(key) =>
              setSelectedSecondaryTab(
                secondaryTabs.find((tab) => tab.key === key)
              )
            }
          />
        );
      })}
    </SecondaryTabsContainer>
  );
};
