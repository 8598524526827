import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import React from "react";
import {
  StyledIcon,
  StyledIconButton,
  StyledModal,
  StyledText,
  StyledTitle,
} from "./styled";

const ModulesModal = ({ onOpen, setOpenModulesModal, type }) => {
  const modulesContent = {
    flows: {
      title: "Admissão Digital",
      icon: ["IconUserPlus"],
      text: "Um RH estratégico automatiza seus processos desde a admissão de colaboradores",
      infos: [
        "Gestão de candidatos simples e com automações",
        "Envio e coleta do aceite da carta proposta",
        "Gestão e coleta de assinatura eletrônica de documentos",
        "Acompanhamento de cada etapa de forma simplificada e totalmente digital",
      ],
    },
    lms: {
      title: "Treinamento",
      icon: "IconSchool",
      text: "Quando você desenvolve seus colaboradores, sua empresa atinge níveis extraordinários de performance",
      infos: [
        "Construa e gerencie programas de treinamento e desenvolvimento com facilidade",
        "Crie e disponibilize treinamentos em vídeo, áudio ou PDF",
        "Valide o conhecimento dos alunos com avaliações personalizadas",
        "Acompanhe os resultados de engajamento de cada curso e desempenho dos alunos",
      ],
    },
    engagement: {
      title: "Engajamento",
      icon: "IconSpeakerphone",
      text: "Supere suas metas de engajamento e alcance um nível excepcional de conexão com seus colaboradores",
      infos: [
        "Criação e envio de mensagens e pesquisas personalizadas para os colaboradores",
        "Mural de notícias e informações da empresa direto no app Flash",
        "Acompanhamento e monitoramento das interações com suas mensagens",
      ],
    },
    orgchart: {
      title: "Organograma",
      icon: "IconHierarchy2",
      text: "O RH se destaca quando a estrutura da organização é visível e acessível para todos os colaboradores",
      infos: [
        "Controle e edição do organograma da empresa de forma simplificada",
        "Visualização clara dos colaboradores por departamento e hierarquia",
        "Atualização em tempo real",
        "Acessível para todos e de fácil compartilhamento",
      ],
    },
    management: {
      title: "Gestão de colaboradores",
      icon: "IconCheckbox",
      text: "Uma solução de gestão de RH que é a sua cara! Todos os benefícios e uma plataforma prática, intuitiva e segura.",
      infos: [
        "Personalização da sua conta dentro da Flash com seu logo e as cores da sua empresa",
        "Cadastro e gestão de administradores simplificada",
        "Segurança e proteção dos dados dos colaboradores e da organização em todas as etapas",
      ],
    },
    analytics: {
      title: "People Analytics",
      icon: "IconChartPie",
      text: "O RH alcança resultados transformadores quando se tem dados inteligentes dos colaboradores",
      infos: [
        "Insights baseados em dados reais",
        "Relatórios atualizados em tempo real",
        "Diversos filtros e facilidade para baixar e compartilhar relatórios",
        "Relatórios de diversidade, funcionários por área e muito mais!",
      ],
    },
    signature: {
      title: "Assinatura eletrônica",
      icon: "IconSignature",
      value: "2,00",
      text: "Agilize seus processos coletando a assinatura dos seus documentos pela nossa plataforma.",
      infos: ["Admissão digital"],
    },
    upload: {
      title: "Upload de conteúdo",
      icon: "IconUpload",
      value: "2,00",
      text: "Envie e armazene seus próprios vídeos dentro da nossa plataforma.",
      infos: ["Treinamentos"],
    },
  };
  const modalHeader = (
    <StyledIconButton
      size="small"
      variant="line"
      icon="IconX"
      onClick={() => setOpenModulesModal(false)}
    />
  );
  const InfosList = ({ info }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Icons name="IconCheck" fill="transparent" color="#F0AAB4" />
        <Typography style={{ marginLeft: "14px" }} variant="body4">
          {info}
        </Typography>
      </div>
    );
  };
  return (
    <StyledModal header={modalHeader} open={onOpen} onClose={() => ({})}>
      <>
        <div style={{ padding: " 6% 24% 0% 24%" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <StyledIcon
              name={modulesContent[type].icon}
              fill="transparent"
              style={{ marginRight: "8px", marginBottom: "9px" }}
            />
            <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
              {modulesContent[type].title}
            </StyledTitle>
          </div>
          <StyledText style={{ fontWeight: 700 }} variant="body2">
            {modulesContent[type].text}
          </StyledText>
          {/* {(type == "signature" || type == "upload") && (
            <StyledValue variant="body3">
              + R$ {modulesContent[type]?.value}
            </StyledValue>
          )} */}
        </div>

        <Divider sx={{ my: "24px" }} />
        <div style={{ padding: " 0% 24% 6% 24%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            {(type == "signature" || type == "upload") && (
              <StyledText style={{ fontWeight: 700 }} variant="body4">
                Você pode usar {modulesContent[type].title.toLowerCase()} para :
              </StyledText>
            )}
            {modulesContent[type].infos.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <InfosList info={item} />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </>
    </StyledModal>
  );
};

export { ModulesModal };
