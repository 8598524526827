import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledAlertText,
  StyledButton,
  StyledDiv,
  StyledIcon,
  StyledIconButton,
  StyledModal,
  StyledText,
  StyledText2,
  StyledTitle,
} from "./styled";

const CancelPlanModal = ({
  title,
  text,
  onOpen,
  onClose,
  handleFunction,
  loading,
}) => {
  const modalHeader = (
    <StyledIconButton
      size="small"
      variant="line"
      icon="IconX"
      onClick={() => {
        onClose();
      }}
    />
  );
  const modalFooter = (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-evenly",
        borderTop: "1px solid #EBE6E9",
      }}
    >
      <StyledButton
        loading={loading}
        style={{
          background: "#FFECD6",
          boxShadow: "none",
          padding: "18px 24px",
        }}
        variant="primary"
        size="medium"
        onClick={() => {
          handleFunction();
        }}
      >
        <StyledAlertText
          variant="body3"
          style={{ fontWeight: 700, color: "#4C2900" }}
        >
          Entendi
        </StyledAlertText>
        <Icons name="IconCheck" size={15} color="#4C2900" fill="transparent" />
      </StyledButton>
      {/* <div>
        <StyledButton
          style={{
            border: "1px solid #C96C01",
            background: "none",
            boxShadow: "none",
            padding: "16px 24px",
          }}
          variant="primary"
          size="medium"
          onClick={() => {
            onClose();
          }}
        >
          <StyledAlertText variant="body3" style={{ fontWeight: 700 }}>
            Manter minha assinatura
          </StyledAlertText>
        </StyledButton>
      </div> */}
    </div>
  );
  return (
    <StyledModal
      header={modalHeader}
      footer={modalFooter}
      open={onOpen}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <StyledDiv>
          <StyledIcon name="IconAlertCircle" fill="transparent" size={48} />
          <StyledAlertText variant="body3" style={{ fontWeight: 700 }}>
            Atenção!
          </StyledAlertText>
          <StyledTitle variant="headline6">{title}</StyledTitle>
          <StyledText
            variant="body3"
            style={{
              whiteSpace: "pre-line",
              textAlign: "center",
            }}
          >
            {text}
          </StyledText>
        </StyledDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "17px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "20px",
            }}
          >
            <Icons name="IconHeadset" fill="transparent" color="#F20D7A" />
            <StyledText2
              style={{ fontWeight: 700, marginLeft: "6px" }}
              variant="body3"
            >
              (11) 4118-0488
            </StyledText2>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Icons name="IconMail" fill="transparent" color="#F20D7A" />
            <StyledText2
              style={{ fontWeight: 700, marginLeft: "6px" }}
              variant="body3"
            >
              empresa@flashapp.com.br
            </StyledText2>
          </div>
        </div>
      </>
    </StyledModal>
  );
};

export { CancelPlanModal };
