import { ContractStatusTag } from "@components/ContractStatusTag";
import {
  StyledBox,
  StyledGapHorizontalContainer,
  StyledHorizontalContainer,
  StyledIcon,
  StyledLink,
  StyledPinkCircleWrapper,
  StyledSmallIcon,
  StyledText,
  StyledTitle,
  StyledVerticalSpacing,
} from "./styled";

const ContractSummaryBox = ({
  status = "CANCELLED",
  onContractClick,
  signDate,
  displayName,
}: {
  status: string;
  onContractClick: () => void;
  signDate: string;
  displayName: string;
}) => {
  return (
    <StyledBox>
      <StyledGapHorizontalContainer style={{ alignItems: "flex-start" }}>
        <StyledPinkCircleWrapper size={60}>
          <StyledIcon name="IconLicense" size={50} style={{ strokeWidth: 1 }} />
        </StyledPinkCircleWrapper>
        <div>
          <StyledHorizontalContainer>
            <StyledTitle variant="body1">{displayName}</StyledTitle>
            <ContractStatusTag status={status} />
          </StyledHorizontalContainer>
          <StyledVerticalSpacing size="xs3" />
          <StyledHorizontalContainer>
            <StyledSmallIcon name="IconHourglass" size={20} />
            <StyledText variant="body3">{`Contratação: ${signDate}`}</StyledText>
          </StyledHorizontalContainer>
          <StyledVerticalSpacing size="xs" />
          <StyledLink variant="primary" onClick={onContractClick}>
            Visualizar contrato completo
          </StyledLink>
        </div>
      </StyledGapHorizontalContainer>
    </StyledBox>
  );
};

export { ContractSummaryBox };
