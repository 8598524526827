import {
  IconButton,
  Icons,
  LinkButton,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  border-radius: 50px;
  overflow: unset;
`;
const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledTag = styled(Tag)`
  cursor: auto !important;
`;

const StyledIconButton = styled(IconButton)``;

const StyledLinkButton = styled(LinkButton)``;

export {
  StyledBox,
  StyledIcon,
  StyledTitle,
  StyledText,
  StyledTag,
  StyledIconButton,
  StyledLinkButton,
};
