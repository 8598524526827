import { useMemo } from "react";

import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledBreadcrumbItem, StyledBreadcrumbText } from "./styled";

const Breadcrumb = ({ breadcrumbs, separator, style }) => {
  const handleBreadcrumbs = useMemo(() => {
    if (!breadcrumbs.length) return [<></>];

    return breadcrumbs.map((b, index) => {
      return b.active ? (
        <StyledBreadcrumbItem onClick={b.onClick} key={index}>
          <StyledBreadcrumbText variant="body4">{b.label}</StyledBreadcrumbText>
        </StyledBreadcrumbItem>
      ) : (
        <StyledBreadcrumbText key={index} variant="body4">
          {b.label}
        </StyledBreadcrumbText>
      );
    });
  }, [breadcrumbs]);

  return (
    <Breadcrumbs
      breadcrumbs={handleBreadcrumbs}
      separator={separator}
      style={style}
    />
  );
};

export { Breadcrumb };
