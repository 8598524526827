import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  gap: 32px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
  height: 100%;
  justify-content: flex-start;
`;

const StyledTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral.dark.dark2};
    font-weight: 700;
  }
`;

const StyledLink = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  cursor: pointer;
`;
const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledTag = styled(Tag)`
  cursor: auto !important;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.feedback.success[90]};
  color: ${({ theme }) => theme.colors.feedback.success[10]};
`;

const StyledHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

const StyledGapHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
`;

export {
  StyledBox,
  StyledTitle,
  StyledLink,
  StyledText,
  StyledTag,
  StyledHorizontalContainer,
  StyledGapHorizontalContainer,
};
