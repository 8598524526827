import {
  Typography,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  gap: 32px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
  height: 100%;
  justify-content: flex-start;
`;

const StyledTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral.dark.dark2};
    font-weight: 700;
  }
`;

const StyledLink = styled(LinkButton)``;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

const StyledGapHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.s};
  align-items: center;
  justify-content: flex-start;
`;

const StyledPinkCircleWrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  background: var(--color-secondary-95);
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
`;

const StyledIcon = styled(Icons)`
  fill: transparent;
  color: ${({ theme }) => theme.colors.secondary[50]};
  stroke-width: 1;
`;

const StyledSmallIcon = styled(Icons)`
  fill: transparent;
  color: ${({ theme }) => theme.colors.neutral[60]};
`;

const StyledVerticalSpacing = styled.div<{ size: string }>`
  height: ${({ theme, size }) => theme.spacings[size]};
`;

export {
  StyledBox,
  StyledTitle,
  StyledLink,
  StyledText,
  StyledHorizontalContainer,
  StyledGapHorizontalContainer,
  StyledPinkCircleWrapper,
  StyledIcon,
  StyledSmallIcon,
  StyledVerticalSpacing,
};
