import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #ff9ac9;
  background: ${({ theme }) => theme.colors.secondary[99]};
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;
  margin-top: 81px;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledLinkButton = styled(LinkButton)``;

export { StyledBox, StyledTitle, StyledText, StyledSubtitle, StyledLinkButton };
