import AppRouter from "./routes";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc, trpcClient, queryClient } from "./api/client";
import { QueryClientProvider } from "@tanstack/react-query";

import "../src/i18n";

export default function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
