import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DataGridHeaderContainer } from "../styled";

export const AmendmentHeader = ({ name }: { name: string }) => {
  return (
    <DataGridHeaderContainer>
      <ShapeIcon
        name="IconFilePlus"
        variant="default"
        color="var(--color-secondary-50)"
        size={40}
      />
      <Typography variant="body3" style={{ fontWeight: 600 }}>
        {name}
      </Typography>
    </DataGridHeaderContainer>
  );
};
