import styled from "styled-components";
import {
  Button,
  IconButton,
  Icons,
  Modal,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  padding: 12px;
  background: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: 48px;
`;

const StyledAlertText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;
const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
  text-align: center;
`;
const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledText2 = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledSubText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  font-weight: 700 !important;
`;

const StyledTextField = styled(TextField)`
  && {
    div.Mui-focused {
      border: 2px solid ${({ theme }) => theme.colors.feedback.error[40]};
    }
  }
  width: 100%;
`;

const StyledButton = styled(Button)``;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const StyledModal = styled(Modal.Root)`
  .modal-content-area {
    padding: 6% 10% !important;
  }
  .modal-container {
    width: 45%;
  }
`;

export {
  StyledIcon,
  StyledAlertText,
  StyledTitle,
  StyledText,
  StyledSubText,
  StyledTextField,
  StyledButton,
  StyledDiv,
  StyledIconButton,
  StyledModal,
  StyledText2,
};
