import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 40px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
  height: 100%;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledLink = styled(LinkButton)``;

export { StyledBox, StyledTitle, StyledText, StyledLink };
