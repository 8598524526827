import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const SecondaryTabsContainer = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Card = styled.button<{ selected: boolean }>`
  padding: 13px 0 13px 16px;
  width: 100%;
  border-radius: 6px;

  text-align: start;

  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-light5)" : "var(--color-neutral-90)"};

  background: ${({ selected }) =>
    selected ? "var(--color-secondary-light2)" : "transparent"};
`;

export const Title = styled(Typography)<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-dark4)" : "var(--color-neutral-dark3)"};
  font-weight: 600 !important;
`;
