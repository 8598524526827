import styled from "styled-components";
import {
  IconButton,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const StyledModal = styled(Modal.Root)`
  .modal-content-area {
    padding: 6% 10% !important;
  }
  .modal-container {
    width: 45%;
  }
`;

const PdfViewerContainer = styled.div`
  //
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  flex-direction: column;
`;

const StyledVerticalSpacing = styled.div`
  height: ${({ theme }) => theme.spacings.xs2};
`;

export {
  StyledText,
  StyledIconButton,
  StyledModal,
  PdfViewerContainer,
  StyledVerticalSpacing,
};
