import { Icons, PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Authentication } from "@flash-hros/auth-helper";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccordionInfo } from "../../components/AccordionInfo";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { CancelPlanModal } from "../../components/CancelPlanModal";
import { ConsultantContactBox } from "../../components/ConsultantContactBox";
import { FooterBar } from "../../components/FooterBar";

import { trpc } from "../../api/client";

import {
  AlertContainer,
  StyledAlertText,
  StyledButton,
  StyledButtonText,
  StyledIcon,
  StyledSecondary,
  StyledText,
  StyledTextArea,
  StyledTitle,
} from "./styled";
import { dispatchToast } from "@utils";
import { segment } from "../../utils/segment";

const CancelPlanPage = () => {
  const buttons = [
    {
      reason: "Preço",
      segment:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_price_button_clicked",
    },
    {
      reason: "Atendimento",
      segment:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_attendance_button_clicked",
    },
    {
      reason: "Dificuldade de uso",
      segment:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_usageissues_button_clicked",
    },
    {
      reason: "Indo para concorrente",
      segment:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_competitor_button_clicked",
    },
    {
      reason: "Não estou usando",
      segment:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_nousage_button_clicked",
    },
    {
      reason: "Outros",
      segment:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_others_button_clicked",
    },
  ];

  const navigate = useNavigate();
  const { getAccessToken } = Authentication;
  const { selectedCompany } = useSelectedCompany();

  const [checkedState, setCheckedState] = useState(
    new Array(buttons.length).fill(false)
  );
  const [reasons, setReasons] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [contract, setContract] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showInfoBox, setShowInfoBox] = useState<boolean>(true);

  const { data, isLoading } = trpc.getContractByCompany.useQuery();
  const contractCompany = data;

  const updateActiveContract = trpc.updateActiveContract.useMutation({
    onSuccess: async () => {
      const updateToken = await getAccessToken({
        companyId: selectedCompany.id,
        isAdmin: true,
      });
      if (updateToken?.company) {
        navigate(`/contract-manager/plan`, { state: { tab: 2 } });
      }
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const BottomInfoAccordion = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "0px 24px 24px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: "20px",
          }}
        >
          <Icons name="IconHeadset" fill="transparent" color="#F20D7A" />
          <StyledSecondary
            style={{ fontWeight: 700, marginLeft: "6px" }}
            variant="body4"
          >
            (11) 4118-0488
          </StyledSecondary>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Icons name="IconMail" fill="transparent" color="#F20D7A" />
          <StyledSecondary
            style={{ fontWeight: 700, marginLeft: "6px" }}
            variant="body4"
          >
            empresa@flashapp.com.br
          </StyledSecondary>
        </div>
      </div>
    );
  };
  const BottomInfoAccordion2 = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "0px 24px 24px 24px",
        }}
      >
        <a
          href="https://beneficios.flashapp.com.br/faq/flash-people"
          target="_blank"
        >
          <StyledText variant="body4" style={{ fontWeight: 700 }}>
            Acessar central
          </StyledText>
        </a>
      </div>
    );
  };
  const BottomInfoAccordion3 = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "0px 24px 24px 24px",
        }}
      >
        <a href="https://www.flashapp.com.br/flash-people" target="_blank">
          <StyledText variant="body4" style={{ fontWeight: 700 }}>
            Saiba mais
          </StyledText>
        </a>
      </div>
    );
  };

  const reasonsArr: any[] = [];

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleClick = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    updatedCheckedState.map((item, index) => {
      if (item) {
        reasonsArr.push(buttons[index].reason);
      }
    });
    setReasons(reasonsArr);
  };

  const handleCancelPlan = async () => {
    segment({
      track:
        "company_managecompany_finance_plansandcontracts_cancelsubscription_attentionmodal_understood_button_clicked",
    });
    if (message != "") {
      reasons.push(message);
    }

    const contractId = contract?._id;

    const body = {
      company: contract?.company,
      products: contract?.products,
      type: contract?.type,
      canceled: {
        reason: reasons,
        date: new Date(Date.now()).toISOString(),
        operator: { _id: "" },
      },
    };

    updateActiveContract.mutate({
      contractId: contractId,
      body: body,
    });
  };

  useEffect(() => {
    (async () => {
      if (contractCompany) {
        const contractPeople =
          contractCompany?.find((c) =>
            c?.product?.some((p) => p.businessUnity == "people")
          ) || {};

        setContract(contractPeople);
      }
    })();
  }, [contractCompany]);

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => {
                  navigate(`/contract-manager/plan`);
                },
                label: "Planos e Contratos",
                active: true,
              },
              // {
              //   onClick: () => {
              //     navigate(`/contract-manager/contract`);
              //   },
              //   label: "Seu contrato",
              //   active: true,
              // },
              {
                onClick: () => ({}),
                label: "Cancelar assinatura",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3} style={{ padding: "0px 32px" }}>
        <Grid item md={12}>
          <StyledTitle variant="headline6"> Cancelar assinatura</StyledTitle>
        </Grid>
        <Grid item md={3} style={{ marginTop: "32px" }}>
          <StyledSecondary variant="headline7" style={{ marginBottom: "8px" }}>
            Cancelamento
          </StyledSecondary>
          <StyledText variant="body3">
            Que pena que você está indo embora. A missão da Flash People é dar
            mais liberdade para a sua gestão de pessoas.
          </StyledText>
        </Grid>
        <Grid item md={9} sx={{ marginTop: "32px" }}>
          <Grid container spacing={3}>
            {showInfoBox && (
              <Grid item md={12}>
                <AlertContainer borderColor="#8ac1fe">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <StyledIcon
                      name="IconAlertCircle"
                      fill="transparent"
                      size={24}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        marginLeft: "24px",
                      }}
                    >
                      <StyledAlertText
                        variant="body3"
                        style={{ fontWeight: 700 }}
                      >
                        O que acontecerá quando eu cancelar minha assinatura?
                      </StyledAlertText>
                      <StyledAlertText variant="body4">
                        &bull; Ainda serão cobradas todas mensalidades pendentes
                      </StyledAlertText>
                      <StyledAlertText variant="body4">
                        &bull; Assine novamente quando quiser em planos e
                        contratos
                      </StyledAlertText>
                    </div>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <Icons
                      name="IconX"
                      fill="transparent"
                      size={18}
                      color="#015FCB"
                      onClick={() => setShowInfoBox(false)}
                    />
                  </div>
                </AlertContainer>
              </Grid>
            )}

            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  Quais são os principais motivos que levaram ao cancelamento?
                </StyledTitle>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "12px",
                  }}
                >
                  {buttons.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <StyledButton
                          variant="secondary"
                          checked={checkedState[index]}
                          size="small"
                          onClick={() => {
                            handleClick(index);
                            segment({ track: item.segment });
                          }}
                        >
                          <StyledButtonText
                            checked={checkedState[index]}
                            variant="body4"
                          >
                            {item.reason}
                          </StyledButtonText>
                        </StyledButton>
                      </React.Fragment>
                    );
                  })}
                </div>
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  Se quiser, deixe um comentário ou sugestão para nos ajudar a
                  melhorar nossa experiência
                </StyledTitle>
                <StyledTextArea
                  value={message}
                  onChange={(e) => handleMessageChange(e)}
                  placeholder="Eu estou cancelando Flash People por..."
                />
              </div>
            </Grid>
            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  flexDirection: "column",
                }}
              >
                <AccordionInfo
                  title={"Entre em contato com nosso time"}
                  segment={() => {
                    segment({
                      track:
                        "company_managecompany_finance_plansandcontracts_cancelsubscription_getintouch_accordion_clicked",
                    });
                  }}
                  text={
                    "Entre em contato com a nossa equipe de atendimento para tirar dúvidas, negociar seu plano e solucionar qualquer problema."
                  }
                  info={<BottomInfoAccordion />}
                />
                <AccordionInfo
                  title={"Central Flash People"}
                  segment={() => {
                    segment({
                      track:
                        "company_managecompany_finance_plansandcontracts_cancelsubscription_flashpeoplecentral_accordion_clicked",
                    });
                  }}
                  text={
                    "Acesse nossa central de ajuda para entender mais como a plataforma funciona e todas as ferramentas que impulsionam seu time para o protagonismo."
                  }
                  info={<BottomInfoAccordion2 />}
                />
                <AccordionInfo
                  title={"Tudo que a Flash People oferece"}
                  segment={() => {
                    segment({
                      track:
                        "company_managecompany_finance_plansandcontracts_cancelsubscription_flashpeopleservices_accordion_clicked",
                    });
                  }}
                  text={
                    "Nossa plataforma oferece todas as ferramentas necessárias para gerenciar seu time rumo ao protagonismo da sua empresa."
                  }
                  info={<BottomInfoAccordion3 />}
                />
              </div>
            </Grid>
            <Grid item md={12} mb={4}>
              <ConsultantContactBox />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FooterBar
        disabled={reasons.length ? true : false}
        nextHandleClick={() => {
          segment({
            track:
              "company_managecompany_finance_plansandcontracts_cancelsubscription_cancelsubscription_button_clicked",
          });
          setOpenModal(true);
        }}
        nextHandleButtonLabel={"Cancelar assinatura"}
        previousHandleClick={() => {
          segment({
            track:
              "company_managecompany_finance_plansandcontracts_cancelsubscription_return_button_clicked",
          });
          navigate(-1);
        }}
        previousHandleButtonLabel={"Voltar"}
      />

      <CancelPlanModal
        title={"Que pena que você está indo embora!"}
        text={
          "Obrigada pelo tempo que esteve conosco! Para continuar o \ncancelamento, entre em contato com nosso time de \natendimento. \n\nVolte quando quiser para reiniciar a sua assinatura. \nEstaremos te esperando de braços abertos!"
        }
        onOpen={openModal}
        handleFunction={() => handleCancelPlan()}
        loading={isLoading}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export { CancelPlanPage };
